import tdrService from "../../services/tdr.service"

const state = {
  datosCargados: false,
  errorCargaDeDatos: false,
  tarjeta: {
    // "numeroContrato": "55443311",
    // "centroDeAlta":"0100"
    // "valorCupo": 200000,
    // "cupoDisponible": null,
    // "cupoTotal": null,
    // "valorPagoMinimo": null,
    // "valorPagoTotal": null,
    // "fechaCorte": null,
    // "fechaLimitePago": null,
    // "saldoMora": 0,
    // "fechaActualizacionInformacion": null,
    // "bloqueada": false,
  },
  transactions: [],
  pendingActivation: false
}

const getters = {
  conSaldoMora: (state) => {
    return  state.tarjeta.saldoMora > 0
  },
  pagoAtrasado: (state) => {
    const fechaActual = new Date()
    const fechaLimite = new Date(state.tarjeta.fechaLimitePago)
    return fechaActual > fechaLimite
  },
  conMoraTemprana: (state) => {
    return state.tarjeta.diasMoraTemprana > 0
  }
}

const actions = {
  async getTarjetaInfo({ commit, state },data) {
    try{
        if(!state.datosCargados) {
          var res = await tdrService.getInfoCreditCard(data)
          commit('setTarjetaInfo',res.data.data.tarjeta)
          commit('setTransactions', res.data.data.tarjeta.ultimasTransacciones)
          commit('setDatosCargados', true)
          commit('setPendingActivation', false)
        }
    } catch(error) {
      if (error.response) {
        const errorMessage = error.response.data.message || ""; // Asegura que errorMessage sea una cadena
        if (errorMessage.includes("No tiene asociado un numero de contrato")) {
          commit('setPendingActivation', true);
          commit('setDatosCargados', false);
        } else if (error.response.status === 404) {
          commit('setPendingActivation', true);
          commit('setDatosCargados', false);
        } else {
          commit('setErrorCargaDatos', true);
        }
      } else {
        commit('setErrorCargaDatos', true); // Maneja el caso donde no haya respuesta del servidor
      }
      //throw new Error("Error cargando datos de TC")
    }
  }
}

const mutations = {
  setTarjetaInfo(state, info) {
    state.tarjeta = info
  },
  setTransactions(state,data) {
    state.transactions = data
  },
  setDatosCargados(state, data) {
    state.datosCargados = data
  },
  setErrorCargaDatos(state, data) {
    state.errorCargaDeDatos = data
  },
  setPendingActivation(state, data) {
    state.pendingActivation = data
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}